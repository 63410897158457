import React from 'react'
import WebTitle from '@/components/Title'

const Index = () => {
  const list = [
    { title: '知识图谱', desc: '可应用于物理空间人物通连关系;常识/情感知识库的建立;也可以应用于大规模肝脏生物学知识图片建立等实际场景中' },
    { title: '图像建模', desc: '可应用于大型场景的多对象光场景重建,也可应用于其他多领域' },
    { title: '自然语言处理', desc: '通过图谱引导的智能知识检索,图谱引导的智能知识推理,图谱引导的智能知识决策一系列处理得以实现' }
  ]
  return <div className='pc-website-index pc-website-teach' id='teach'>
    <WebTitle title='技术'/>
    <div className='pc-website-teach-wrap'>
      {
        list.map((item, index) => <div className='pc-website-teach-wrap-item' key={index}>
          <img src={require(`@/assets/tech-${index + 1}.png`)} alt="" />
          <div>{item.title}</div>
          <p>{item.desc}</p>
        </div>)
      }
    </div>
  </div>
}

export default Index
