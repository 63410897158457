import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { useScroll } from 'ahooks'

const Index = () => {
  const scroll = useScroll(document) ?? 0
  const [fixed, setFixed] = useState<boolean>(false)
  const list = [
    { title: '简介', id: 'intro' },
    { title: '技术', id: 'teach' },
    { title: '项目', id: 'desc' }
  ]
  useEffect(() => {
    const { top } = scroll as {
      left: number
      top: number
    }
    if (top > 0) setFixed(true)
    else setFixed(false)
  }, [scroll])

  const handleJump = (id: string) => {
    const dom = document.getElementById(id)
    if (dom) {
      dom.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }

  return <>
    <div className='pc-website-header-sticky'/>
    <div className={classNames('pc-website-header', { fixed })}>
      <div className='pc-website-header-logo'><img src={require('@/assets/logo.png')} /></div>
      <ul>
        {
          list.map(item => <li key={item.title} onClick={() => handleJump(item.id)}>{item.title}</li>)
        }
      </ul>
    </div>
  </>
}

export default Index
