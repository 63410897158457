import React, { FC } from 'react'

interface PropsType {
  title: string
}
const Index: FC<PropsType> = ({ title }) => {
  return <div className='pc-website-title'>{title}</div>
}

export default Index
