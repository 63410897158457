import React from 'react'
import WebTitle from '@/components/Title'

const Index = () => {
  return <div className='pc-website-index web-pc-website-intro' id='intro'>
    <WebTitle title='简介'/>
    <div className='web-pc-website-intro-cont'>
      <p>
        北京华方芯微科技有限公司成立于2021年 <br />
        主要技术优势涵盖人工智能、大数据、物联网等技术，涉及行业包括:智慧博物馆建设、智慧医疗和能源大数据产业。
      </p>
      <img src={require('@/assets/school.png')} />
    </div>
  </div>
}

export default Index
