import React from 'react'

import WebHeader from '@/components/Header'
import Banner from './components/Banner'
import Intro from './components/Intro'
// import Team from './components/Team'
import Teach from './components/Teach'
import Desc from './components/Desc'
import Footer from './components/Footer'

function Home() {
  return (
    <div className='pc-website-home'>
      <WebHeader/>
      <Banner/>
      <Intro/>
      {/* <Team/> */}
      <Teach/>
      <Desc />
      <Footer/>
    </div>
  )
}

export default Home
