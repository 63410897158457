import React from 'react'
import WebTitle from '@/components/Title'

const Index = () => {
  return <div className='pc-website-index pc-website-desc' id='desc'>
    <WebTitle title='智慧文物介绍'/>
    <ul>
      <li>以虚拟文物通过终端(电脑、手机等) 为主，以展厅分割归类文物并引导游客。 进行展示，游客可以通过终端自主获取 游客需要亲身走进馆内查看文物展览。 文物信息，甚至3D模型。</li>
      <li>利用知识图谱构建技术，实现海量文物之间认知关联。 帮助游客从获取的感知数据提炼出认知知识，使得游 客浏览体验从感知层面跨入认知层面。 对文物进行超分辨率3D建模，辅助MR眼镜，保护文物 同时满足游客近距离观看、把玩文物的需求。甚至通 过设计小游戏，增强文物和观众之间的互动性。</li>
    </ul>
    <img src={require('@/assets/desc.png')}/>
  </div>
}

export default Index
