import React from 'react'

const Index = () => {
  const handleJump = () => {
    const dom = document.getElementById('intro')
    if (dom) {
      dom.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    }
  }
  return <div className='pc-website-banner' id='banner'>
    <img src={require('@/assets/banner.png')}/>
    <div className='pc-website-banner-desc'>
      <h1>北京华方芯微科技有限公司</h1>
      <p>人工智能 | 大数据 |  物联网</p>
      <button onClick={handleJump}>了解更多</button>
    </div>
  </div>
}

export default Index
